.quicklink
    text-decoration: underline
    color: var(--default)
    &:hover
        color: var(--hover)
    &:active
        color: var(--pressed)
    &.disabled
        pointer-events: none
        color: var(--grayscale30)
    span
        display: inline-block
        position: relative
        vertical-align: baseline
    &.font-semibold-12
        span
            height: 16px
            width: 16px
            transform: translateY(3px)
    &.font-semibold-14
        span
            height: 20px
            width: 20px
            transform: translateY(4px)
    &.font-semibold-16
        span
            height: 24px
            width: 24px
            transform: translateY(6px)