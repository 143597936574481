.popover
    display: inline-block
    cursor: help
    .hover
        position: relative
        display: inline-block
        background: var(--light1)
        color: var(--default)
        border-radius: 50%
        border: 1px solid var(--default)
        font-weight: bold
        font-size: .8em
        padding: .6em .4em
        line-height: 0
