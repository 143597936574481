:root
    --grayscale00: #1E1240
    --grayscale10: #4D4467
    --grayscale20: #726C85
    --grayscale30: #ACA9B8
    --grayscale40: #D9D7DD
    --grayscale50: #EBEAED
    --grayscale60: #F4F3F5
    --grayscale70: #FFFFFF
    
    --pressed: #4D1FAF
    --default: #784CD4
    --hover: #AA86F4
    --hover2: #D1C2F1
    --light1: #E9E3F6
    --light2: #F5F2FB

    --error: #DB5050
    --success: #7EC05F

    --additional00: #FF7A92
    --additional10: #FFB35A
    --additional20: #6889FF
    --additional30: #3AC6A4
