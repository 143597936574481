.input
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    gap: 2px
    position: relative
    --padding: 16px
    +maxH(700px)
        --padding: 10px
    --height: calc(24px - 3px) 
    :nth-child(1):not(input)
        position: absolute
        top: 50%
        height: var(--height)
        aspect-ratio: 1/1
        transform: translateY(-50%)
        left: var(--padding)
        & + input
            padding-left: calc(var(--padding) + 2*var(--height))
            width: 100%
    .icon-right
        width: 100%
        + :not(input)
            position: absolute
            top: 50%
            height: var(--height)
            aspect-ratio: 1/1
            transform: translateY(-50%)
            right: var(--padding)
    &.showed-message
        :nth-child(1):not(input), .icon-right + :not(input)
            top: calc(50% - 8px)


    input
        width: 100%
        height: 100%
        font-size: 1rem
        line-height: 1.5rem
        padding: var(--padding)
        color: var(--grayscale00)
        border-radius: 4px
        border: 1px solid var(--grayscale40)
        background: var(--grayscale70)
        &.icon-right
            padding-right: calc(var(--padding) + 2*var(--height))

        &::placeholder
            color: var(--grayscale30)

        &:hover
            border: 1px solid var(--hover)

        &:active, &:focus, &:focus-visible
            border: 1px solid var(--default)
            outline: none
            &::placeholder
                color: var(--grayscale70)
        &:disabled
            border: 1px solid var(--grayscale40)
            background: var(--grayscale60)
            &::placeholder, & + .additionalText
                color: var(--grayscale30)
        &.valid-error
            border: 1px solid var(--error)
        & ~ .additionalText
            font-size: .75rem
            line-height: 1rem
            font-weight: 700
            &.error
                color: var(--error)