button
    display: flex
    gap: 8px
    border-radius: 4px
    &.primary
        background: var(--default)
        color: var(--grayscale70)
        border: none
        &:hover
            background: var(--hover)
        &:active
            background: var(--pressed)
        &:disabled
            border: none
            &:hover, &:active
                border: none

    &.secondary
        background: var(--grayscale70)
        color: var(--grayscale00)
        border: 1px solid var(--default)
        &:hover
            background: var(--light2)
        &:active
            background: var(--light1)

    &.tertiary
        background: var(--grayscale70)
        color: var(--grayscale00)
        border: 1px solid var(--grayscale40)
        &:hover
            background: var(--grayscale60)
        &:active
            background: var(--grayscale50)
            border-color: var(--grayscale30)

    &:disabled
        background: var(--grayscale40)
        color: var(--grayscale20)
        border: 1px solid var(--grayscale30)
        &:hover, &:active
            background: var(--grayscale40)
            color: var(--grayscale20)
            border: 1px solid var(--grayscale30)

    &.font-semibold-12
        gap: 6px
        padding: 8px 12px
        span
            height: 16px
            width: 16px
    &.font-bold-14
        padding: 12px 16px
        span
            height: 20px
            width: 20px
    &.font-bold-16
        padding: 16px 20px
        span
            height: 24px
            width: 24px