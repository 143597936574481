nav
    display: flex
    flex-direction: row
    justify-content: space-between
    padding: 28px 60px
    position: absolute
    width: 100vw
    .nav__item
        margin: auto 0
        &__logo
            img
                max-width: 154px
        .menu
            margin-bottom: 25px
            display: flex
            gap: 42px
            justify-content: space-between
            &__item
                text-decoration: none
    .hamburger
        position: relative
        input
            position: absolute
            transform: scale(2)
            opacity: 0
            z-index: 10
            &:checked
                & ~ span
                    &:nth-child(2)
                        transform: translateY(225%) rotate(45deg)
                    &:nth-child(3)
                        opacity: 0
                    &:nth-child(4)
                        transform: translateY(-225%) rotate(-45deg)
                        
        span
            display: block
            width: 24px
            height: 3px
            border-radius: 24px
            margin-bottom: 4px
            background: var(--grayscale00)
            transition: all 200ms ease