.checkbox
    display: inline-block
    input
        display: none
        &:disabled
            & + label
                background: var(--grayscale50)
                &:hover, &:active
                    border-color: var(--grayscale40)
        &:checked
            & + label
                background: var(--default)
                border-color: var(--default)
                &:hover
                    background: var(--hover)
                    border-color: var(--hover)
                &:active
                    background: var(--pressed)
                    border-color: var(--pressed)
                svg
                    display: inline-block
            &:disabled
                & + label
                    background: var(--grayscale30)
                    border-color: var(--grayscale30)
                    &:hover, &:active
                        border-color: var(--grayscale30)
    label
        display: inline-block
        position: relative
        width: 16px
        height: 16px
        background: var(--grayscale70)
        border: 2px solid var(--grayscale40)
        border-radius: 2.5px
        &:hover
            border-color: var(--hover)
        &:active
            border-color: var(--pressed)
        svg
            color: var(--grayscale70)
            display: none
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%) scale(0.6)
            width: 16px
            height: 11px
.switch
    display: inline-block
    input
        display: none
        & + label
            display: inline-block
            background: var(--grayscale30)
            width: 40px
            height: 24px
            border-radius: 40px
            padding: 2px
            transition: background 100ms ease 
            &:hover
                background: var(--grayscale20)
            &:active
                background: var(--grayscale10)
            &::before
                content:''
                display: inline-block
                background: var(--grayscale70)
                width: 20px
                height: 20px
                border-radius: 50%
                transition: transform 200ms ease 
        &:checked
            & + label
                background: var(--default)
                &::before
                    transform: translateX(calc(100% - 4px))
                &:hover
                    background: var(--hover)
                &:active
                    background: var(--pressed)
            &:disabled
                & + label
                    background: var(--grayscale30)
                    &::before
                        background: var(--grayscale50)
        &:disabled
            & + label
                background: var(--grayscale30)
                &::before
                    background: var(--grayscale50)

.segmentedControl
    $darker-pressed: #331575
    $disabled: #414044
    --padding: 4px
    input
        display: none
        &:checked
            & + .segment
                    &:before, .slider
                        transform: translateX(calc(100% + 4px))
                    label
                        &:nth-child(2)
                            color: inherit
                            &:hover
                                background: var(--grayscale60)
                                color: var(--grayscale10)
                                cursor: pointer
                        &:nth-child(3)
                            color: $darker-pressed
                            &:hover
                                background: none
                                cursor: default
        &:disabled
            &:checked
                & + .segment
                    label
                        color: var(--grayscale30) !important
                        &:hover
                            background: none
                            cursor: default
                        &:nth-child(3)
                            color: $disabled !important
            & + .segment
                &:before
                    background: var(--grayscale40)
                    border-color: var(--grayscale40)
                label
                    color: var(--grayscale30)
                    background: none
                    cursor: default
                    &:nth-child(2)
                        color: $disabled
    .segment
        display: flex
        position: relative
        padding: var(--padding)
        flex-direction: row
        align-items: stretch
        gap: 4px
        border: 1px solid var(--grayscale40)
        border-radius: 4px
        &:before, .slider
            content: ''
            position: absolute
            min-height: 56px
            width: calc(50% - 6px)
            padding: 15px 30px
            background: var(--light1)
            border: 1px solid var(--default)
            border-radius: 4px
            transition: transform 300ms ease
        .slider
            background: transparent
            border: none
            border-radius: 0
            z-index: 1
        label
            display: flex
            mix-blend-mode: hard-light
            min-height: calc(56px - 2* 15px)
            width: 50%
            padding: 15px 30px
            justify-content: center
            align-items: center
            border-radius: 4px
            user-select: none
            transition: color 300ms ease
            color: var(--grayscale20)
            &:hover
                background: var(--grayscale60)
                color: var(--grayscale10)
                cursor: pointer
            &:nth-child(2)
                color: $darker-pressed
                &:hover
                    background: none
                    cursor: default
                


