@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;700&display=swap')

:root
    --font-size: 16px
    --font-bold: 700
    --font-semibold: 600
    --font-regular: 400
    --font-light: 300
    +maxH(700px)
        --font-size: 14px
    
*
    font-family: Mulish, sans-serif
h1
    font-size: 2.25rem
    font-weight: 700
    line-height: 2.75rem
    +maxW(600px)
        font-size: 1.875rem
        line-height: 2.25rem
    +maxW(400px)
        font-size: 1.625rem
        line-height: 2rem
h2
    font-size: 1.75rem
    font-weight: 700
    line-height: 2rem
    +maxW(600px)
        font-size: 1.375rem
        line-height: 1.75rem
    +maxW(400px)
        font-size: 1.125rem
        line-height: 1.5rem
h3
    font-size: 1.5rem
    font-weight: 700
    line-height: 1.75rem
    +maxW(600px)
        font-size: 1.125rem
        line-height: 1.625rem
    +maxW(400px)
        font-size: 1rem
        line-height: 1.5rem
h4
    font-size: 1.25rem
    font-weight: 700
    line-height: 1.5rem
    +maxW(600px)
        font-size: 1rem
        line-height: 1.5rem
    +maxW(400px)
        font-weight: 600
.font
    @each $style in 'light','regular', 'semibold', 'bold'
        &-#{$style}
            font-weight: var(--font-#{$style})
            @for $i from 0 through 3 
                &-1#{$i * 2}
                    font-weight: var(--font-#{$style})
                    font-size: #{.625 + $i * .125}rem // .625, .75, .875, 1
                    line-height: #{.75 + $i * .25}rem // .75, 1, 1.25, 1.5
.font-light-12, .font-regular-12
    line-height: 0.875rem



